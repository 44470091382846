
import Swiper from 'swiper'
export default {
  name: 'ThumbsSlider',
  props: {
    blockOptions: {
      type: Object
    },
    images: {
      type: Array,
      default: () => []
    },
    content: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      activeIndex: 0,
      socialPopoverVisible: false
    }
  },
  computed: {
    slidesCount () {
      return this.blockOptions.Images.length
    }
  },
  mounted () {
    const swiperThumbs = new Swiper('.mySwiper', {
      centeredSlides: true,
      // loop: true,
      loop: true,
      loopedSlides: 5,
      observer: true,
      observeParents: true,
      spaceBetween: 24,
      // freeMode: true,
      watchSlidesProgress: true,
      threshold: 10,
      breakpoints: {
        1023: {
          slidesPerView: 4.5,
        },
        767: {
          slidesPerView: 3.5,
        },
        0: {
          slidesPerView: 2.5,
        }
      }
    })
    const swiperTop = new Swiper('.mySwiper2', {
      navigation: {
        prevEl: '.thumbs-slider__btn.btn-prev',
        nextEl: '.thumbs-slider__btn.btn-next'
      },
      loop: true,
      loopedSlides: 5,
      // autoplay: {
      //   delay: 2500,
      //   disableOnInteraction: false
      // },
      thumbs: {
        swiper: swiperThumbs
      }
    })

    swiperTop.on('activeIndexChange', () => {
      this.activeIndex = swiperTop.activeIndex
    })
  }
}
